<template>
  <!-- 账户管理 -->
  <div class="box100">
    <div class="box100 flexCB">
      <div class="departLeft">
        <div class="el-icon-plus settingAdd"  @click="handleAdd"><span> 新增</span></div>
        <div class="departTree">
          <p class="treeP" :class="accId == item.id? 'treePActive': ''" 
            v-for="(item, index) in accountList" :key="index" @click="handNode(index)">{{ item.name }}</p>
        </div>
      </div>
      <div class="departRight scrollBox">
        <div class="perTop flexCB">
          <div class="userbox">
            <div class="flexAC"><div class="per_p"></div><p class="per_tit">用户信息</p></div>
            <div class="user_info">
              <div class="flexCB">
                <p class="user_p">账号</p>
                <p class="user_t">{{infoObj.account}}
                  <span style="color: #FF4C4C;margin-left: 12px;cursor: pointer;" @click="cancelAccount">注销账号</span>
                </p>
              </div>
              <div class="flexCB"><p class="user_p">名称</p><p class="user_t">{{infoObj.name}}</p></div>
              <!-- <div class="flexCB">
                <p class="user_p">密码</p>
                <p class="user_t">
                  <span v-if="!passwordShow">{{item.password}}</span>
                  <span v-if="passwordShow">********</span>
                  <img src="../../assets/img/yincang.png" v-if="!passwordShow" style="margin-left: 12px;cursor: pointer;" @click="passwordShow=true">
                  <img src="../../assets/img/1.png"  v-if="passwordShow" style="margin-left: 12px;cursor: pointer;" @click="passwordShow=false">
                  <img src="../../assets/img/xiugai.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="changPwd">
                </p>
              </div> -->
              <div class="flexCB"><p class="user_p">app_key</p>
              <p class="user_t">
                <span v-if="!keyShow">{{infoObj.app_key}}</span>
                <span v-if="keyShow">********</span>
                <img src="../../assets/img/yincang.png" v-if="!keyShow" style="margin-left: 12px;cursor: pointer;" @click="keyShow=true">
                <img src="../../assets/img/1.png"  v-if="keyShow" style="margin-left: 12px;cursor: pointer;" @click="keyShow=false">
                </p>
              </div>
              <div class="flexCB"><p class="user_p">app_sign</p>
                <p class="user_t" >
                  <span v-if="!signShow">{{infoObj.app_sign}}</span>
                  <span v-if="signShow">********</span>
                  <img src="../../assets/img/yincang.png" v-if="!signShow" style="margin-left: 12px;cursor: pointer;" @click="signShow=true">
                  <img src="../../assets/img/1.png"  v-if="signShow" style="margin-left: 12px;cursor: pointer;" @click="signShow=false">
                </p>
              </div>
              <div class="flexCB"><p class="user_p">创建时间</p><p class="user_t">{{infoObj.creatime}}</p></div>
            </div>
          </div>
          <div class="apibox">
            <div class="flexAC"><div class="per_p"></div><p class="per_tit">API管理</p></div>
            <div class="user_info">
              <div class="url_one">
                <p class="user_p">告警url
                  <img src="../../assets/img/xiugai.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="changeURL(1, accountList.url1)">
                  <!-- <img src="../../assets/img/shanchu.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="deleteURL(1, APIList.alarmurl)"> -->
                </p>
                <p class="user_t" >{{infoObj.url1}}</p>
              </div>
              <div class="url_one">
                <p class="user_p">心跳url
                  <img src="../../assets/img/xiugai.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="changeURL(2, accountList.url2)">
                  <!-- <img src="../../assets/img/shanchu.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="deleteURL(2, APIList.hearturl)"> -->
                </p>
                <p class="user_t">{{infoObj.url2}}</p>
              </div>
              <div class="url_one">
                <p class="user_p">其他url
                  <img src="../../assets/img/xiugai.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="changeURL(3, accountList.url3)">
                  <!-- <img src="../../assets/img/shanchu.png" alt="" style="margin-left: 12px;cursor: pointer;" @click="deleteURL(3, APIList.otherurl)"> -->
                </p>
                <p class="user_t">{{infoObj.url3}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="perlist scrollBox">
          <div class="flexAC"><div class="per_p"></div><p class="per_tit">权限管理</p></div>
          <div class="flexCB">
            <div class="flexAC" style="margin: 16px 0px;">
              <el-input v-model="name"  placeholder="请输入厂商" class="inputSearch"></el-input>
              <el-button  class="btnSearch"  @click="handleSearch">搜索</el-button>
              <el-button  class="btnReset"  @click="handleReset">重置</el-button>
            </div>
            <div class="el-icon-plus settingAdd"  @click="perAdd"><span> 新增</span></div>
          </div>
          <div class="table_cont">
            <el-table
              :data="perList"
              :header-cell-style="{background:'#F6F9FF'}">
              <el-table-column label="序号" width="80">
                <template #default="scope"><span>{{(page - 1) * size + scope.$index + 1}}</span></template>
              </el-table-column>
              <el-table-column
                label="厂商"
                prop="manufacturer_name">
              </el-table-column>
              <el-table-column
                label="型号"
                prop="dock_model_name">
              </el-table-column>
              <el-table-column
                label="设备类型"
                prop="equ_type_name">
              </el-table-column>
              <el-table-column
              label="操作">
                <template slot-scope="scope">
                  <el-button type="text" size="small"  style="color:#FF4C4C;" @click="handleDelete(scope.row)">删除</el-button>
                </template>
              </el-table-column>
            </el-table>

            <el-pagination
              @current-change="pageChange"
              :current-page="page"
              :page-sizes="[10]"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
              class="settingPage">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
    <!-- 新增账号名称弹窗 -->
    <el-dialog title="新增" :visible.sync="userShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="userCancel">
      <el-form :model="userfrom" :rules="userformRules" ref="userFrom">
        <el-form-item label="账号" prop="account">
          <el-input v-model="userfrom.account"  placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="userfrom.name"  placeholder="请输入名称"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="userCancel">取消</div>
        <div class="settingConfirm" @click="userConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 权限列表新增弹窗 -->
    <el-dialog title="新增" :visible.sync="fromShow" center width="460px" class="gyDialog" :close-on-click-modal="false" :before-close="FromCancel">
      <el-form :model="dockfrom" :rules="formRules" ref="dockFrom">
        <el-form-item label="型号" prop="eqcid">
          <el-select v-model="dockfrom.eqcid" placeholder="请选择型号" multiple style="width:100%" @change="getTypeId($event)">
            <el-option v-for="item in typeList"
              :key="item.infoid"
              :label="item.dock_model_name"
              :value="item.infoid">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="FromCancel">取消</div>
        <div class="settingConfirm" @click="FromConfirm">确定</div>
      </div>
    </el-dialog>
    <!-- 修改密码弹窗 -->
    <!-- <el-dialog title="修改密码" :visible.sync="showPwd" width="460px" center class="gyDialog" :close-on-click-modal="false" :before-close="pwdCancel">
      <el-form :model="pwdfrom" :rules="PwdformRules" ref="pwdFrom">
        <el-form-item label="新密码"  prop="password">
          <el-input type="password" v-model="pwdfrom.password"  placeholder="请输入新密码"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="pwdCancel">取消</div>
        <div class="settingConfirm" @click="pwdFromConfirm">确定</div>
      </div>
    </el-dialog> -->

    <!-- API弹窗 -->
    <el-dialog :title="diaName" :visible.sync="showUrl" width="460px" center class="gyDialog" :close-on-click-modal="false" :before-close="urlCancel">
      <el-form :model="urlfrom" :rules="urlformRules" ref="urlFrom">
        <el-form-item label="告警url"  prop="url1" v-if="APIType === 1">
          <el-input  v-model="urlfrom.url1"  placeholder="请输入告警url"></el-input>
        </el-form-item>
        <el-form-item label="心跳url"  prop="url2" v-if="APIType === 2">
          <el-input  v-model="urlfrom.url2"  placeholder="请输入心跳url"></el-input>
        </el-form-item>
        <el-form-item label="其他url"  prop="url3" v-if="APIType === 3">
          <el-input  v-model="urlfrom.url3"  placeholder="请输入其他url"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel" @click="urlCancel">取消</div>
        <div class="settingConfirm" @click="urlFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { accoutDrop, accoutAdd, accoutUrl, accoutAuth, accoutAuthAdd, buttDrop, accoutLogout, authDel } from '@/api/port.js'
export default {
  data () {
    const validatePass = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请输入新密码'))
      } else if (value.toString().length < 6 || value.toString().length > 8) {
        callback(new Error('密码长度为6-8位'))
      } else {
        callback()
      }
    }
    return {
      accId: '',
      accountList: [],
      infoObj: {},
      name: '',
      page: 1,
      size: 10,
      total: 0,
      perList: [],
      // APIList: [],
      passwordShow: true,
      signShow: true,
      keyShow: true,
      fromShow: false,
      dockfrom: {
        id: '',
        infoid: ''
      },
      formRules: {
        eqcid: [
          { required: true, trigger: 'blur', message: '型号不能为空' }
        ]
      },
      infoId: '',
      typeList: [],
      userShow: false,
      userfrom: {
        name: '',
        account: ''
      },
      userformRules: {
        name: [
          { required: true, trigger: 'blur', message: '名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        account: [
          { required: true, trigger: 'blur', message: '账号不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      // showPwd: false,
      // pwdfrom: {
      //   password: ''
      // },
      // PwdformRules: {
      //   password: [{ required: true, validator: validatePass, trigger: 'blur' }],
      // },
      diaName: '新增',
      showUrl: false,
      urlfrom: {
        url: '',
        type: '',
        url1: '',
        url2: '',
        url3: ''
      },
      urlformRules: {
        url1: [{ required: true, trigger: 'blur', message: '告警url不能为空' }],
        url2: [{ required: true, trigger: 'blur', message: '心跳url不能为空' }],
        url3: [{ required: true, trigger: 'blur', message: '其他url不能为空' }]
      },
      APIType: 1,
      infoidList: []
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      // 侧边查询
      accoutDrop().then(res => {
        if (res.status === 1) {
          if (res.data.length > 0) {
            this.accountList = res.data
            this.infoObj = this.accountList[0]
            this.accId = this.accountList[0].id
            this.$nextTick(() => {
              this.getInit()
            })
          }
        }
      })
    },
    handNode (inex) {
      this.infoObj = this.accountList[inex]
      this.accId = this.accountList[inex].id
      this.$nextTick(() => {
        this.getInit()
      })
    },
    getInit () {
      // 权限列表查询
      const params = {
        id: this.accId,
        name: this.name,
        page_index: this.page,
        page_size: this.size
      }
      accoutAuth(params).then(res => {
        if (res.status === 1) {
          this.perList = res.data.data
          this.total = res.data.total
        } else {
          this.perList = []
          this.total = 0
        }
      })
    },
    // 新增用户(左侧)
    handleAdd () {
      this.userShow = true
    },
    // 新增用户取消
    userCancel () {
      this.$refs.userFrom.resetFields()
      this.userShow = false
    },
    // 新增用户提交
    userConfirm () {
      this.$refs.userFrom.validate((valid) => {
        if (!valid) return
        const params = {
          name: this.userfrom.name,
          account: this.userfrom.account
        }
        accoutAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.userCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 注销账号
    cancelAccount () {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '注销账号', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        accoutLogout({ id: this.accId }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    // API新增[修改]
    changeURL (type) {
      this.showUrl = true
      this.APIType = type
      // if (type === 1) {
      //   this.accountList.url1 = item
      // } else if (type === 2) {
      //   this.accountList.url2 = item
      // } else if (type === 3) {
      //   this.accountList.url3 = item
      // }
      // if (item == '') {
      //   this.diaName = '新增'
      // } else {
      //   this.diaName = '编辑'
      // }
    },
    // API修改取消
    urlCancel () {
      this.$refs.urlFrom.resetFields()
      this.showUrl = false
    },
    // API修改提交
    urlFromConfirm () {
      this.$refs.urlFrom.validate((valid) => {
        if (!valid) return
          let params = {}
          if (this.APIType === 1) {
            params = { id: this.accId, url: this.urlfrom.url1, type: this.APIType }
          } else if (this.APIType === 2) {
            params = { id: this.accId, url: this.urlfrom.url2, type: this.APIType }
          } else if (this.APIType === 3) {
            params = { id: this.accId, url: this.urlfrom.url3, type: this.APIType }
          }
          accoutUrl(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.urlCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
      })
    },
    // 权限列表新增
    perAdd () {
      this.fromShow = true
      // 型号下拉
      buttDrop().then(res => {
        if (res.status === 1) {
          this.typeList = res.data
        }
      })
    },
    // 权限列表取消
    FromCancel () {
      this.$refs.dockFrom.resetFields()
      this.fromShow = false
    },
    getTypeId (e) {
      this.infoidList = e
    },
    // 权限列表提交
    FromConfirm () {
      this.$refs.dockFrom.validate((valid) => {
        if (!valid) return
        const params = {
          id: this.accId,
          infoid: this.infoidList
        }
        accoutAuthAdd(params).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.FromCancel()
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 权限列表删除
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        authDel({ id: this.accId, infoid: row.infoid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.getInit()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    },
    pageChange (page) {
      this.page = page
      this.getInit()
    },
    handleSearch () {
      this.getInit()
    },
    handleReset () {
      this.name = ''
      this.page = 1
      this.getInit()
    },
    // 修改密码
    // changPwd () {
    //   this.showPwd = true
    // },
    // pwdCancel () {
    //   this.$refs.pwdFrom.resetFields()
    //   this.showPwd = false
    // },
    // pwdFromConfirm () {
    //   this.$refs.pwdFrom.validate((valid) => {
    //     if (!valid) return
    //     const params = {
    //       id: this.accId,
    //       password: this.pwdfrom.password
    //     }
    //     accoutUpdate(params).then(res => {
    //       if (res.status === 1) {
    //         this.$message.success(res.message)
    //         this.pwdCancel()
    //         this.init()
    //       } else {
    //         this.$message.error(res.message)
    //       }
    //     })
    //   })
    // },
  }
}
</script>

<style scoped>
.departLeft{
  width: 220px;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;
  background: #FFFFFF;
  text-align: left;
}
.departRight{
  width: calc(100% - 228px);
  height: 100%;
  position: relative;
  
}
.perTop{
  width: 100%;
  height: 246px;
  position: relative;
}
.perlist {
  width: 100%;
  height: calc(100% - 254px);
  background: #fff;
  margin-top: 8px;
  padding: 16px;
  box-sizing: border-box;
}
.userbox, .apibox {
  width: 460px;
  height: 100%;
  background: #fff;
  position: relative;
  padding: 16px;
  box-sizing: border-box;
}
.apibox {
  width: calc(100% - 468px);
}
.per_p {
  width: 4px;
  height: 15px;
  background: #5473E8;
  border-radius: 1px;
  margin-right: 4px;
}
.per_tit {
  font-size: 16px;
  font-family: 'PingFang SC-Bold, PingFang SC';
  font-weight: bold;
  color: #455D78;
}
.treeP {
  padding: 0 30px;
  font-size: 14px;
  height: 54px;
  line-height: 54px;
  margin-top: 16px;
  cursor: pointer;
}
.treePActive{
  color: #5473E8;
  background: #F6F9FF;
}
.user_info {
  font-size: 14px;
  font-family: 'PingFang SC-Regular, PingFang SC';
  font-weight: 400;
  color: #455D78;
  text-align: left;
  line-height: 30px;
  padding: 12px 0;
}
.user_p {
  width: 20%;
}
.user_t {
  color: #333333;
  width: 75%;
}
.user_r {
  color: #5473E8;
}
.url_one {
  height: 60px;
}
</style>
